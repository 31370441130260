import { keepPreviousData, useMutation, useQueries, useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { ExclamationMark } from 'tabler-icons-react';
import { useRouter } from 'next/router';
import { showNotification } from '@mantine/notifications';
import moment from 'moment';
import { useAppContext } from '../../../contexts/appContext';
import useStore from '../components/state/state';
import {
    createNewSkuGroup,
    deleteStrategy,
    getAccountsInfo,
    getAggregatedMetrics,
    getAutoOptimizedPriorities,
    getAvailableExclusions,
    getControlsTaskSummary,
    getDailySummary,
    getFilterValues,
    getPocExplorer,
    getPocExplorerSummary,
    getRecommendations,
    getSkusTasksBreakdown,
    getStrategyAccounts,
    getStrategyById,
    getStrategyChannels,
    getStrategyDataAlt,
    getStrategyForecast,
    getStrategyOrdersMetrics,
    getStrategyTasksBreakdown,
    listOverview,
    listSkuGroups,
    regenerateTasks,
    scheduleStrategy,
    unscheduleStrategy,
    updateSkuPriorities,
    updateStrategy,
} from './api';
import {
    Account,
    DeleteStrategyPropsType,
    Recommendation,
    Strategy,
    StrategyTaskType,
} from '../util/types';
import { useSkuInfo } from '../../../hooks/helm/useSkuInfo';
import { convertProjectionData } from '../../../hooks/helm/util';
import { parseSkuForClient } from '../pages/trackStrategy/breakdown/util';
import { useClientTranslation } from '../../../hooks/helm/useClientTranslation';
import { HelmRoutes } from '../util/routes';
import { SkuPriority } from '../pages/previewStrategy/types';
import { CACHE_MAX_DURATION } from '../../../utils/reactQueryConfig';
import {
    buildDemoStrategyName,
    buildNearbyMexicoStores,
    mapStoreToDemoLocation,
    seasonalDiffWithDayMatch,
} from '../util/util';
import { ClientOptions } from '../util/config';
import { formatStrategyOverviewPocLocations } from '../pages/overview/utils';
import {
    addAlgoSellingStats,
    buildTableRows,
    calculateCumulativeSums,
    createQuery,
    DEMO_SCALING,
    fillMissingDates,
    mapSkusToList,
    mapToFormat,
    splitFilters,
} from './utils';

export const useAutoOptimizedPriorities = ({
    strategyId,
    enabled,
}: {
    strategyId: string;
    enabled: boolean;
}) => {
    const { user, environment } = useAppContext();

    const { data, isLoading, error } = useQuery({
        queryKey: ['AutoOptimizedPriorities', strategyId, enabled],
        queryFn: () => getAutoOptimizedPriorities(user?.token, strategyId, environment),
        enabled: Boolean(strategyId) && !!enabled,
    });

    const mappedPriorities: SkuPriority[] = useMemo(
        () =>
            data?.task_priorities
                ?.map(row => ({
                    sku: row.task_name,
                    priority: row.priority,
                    task_type: row.task_subtype,
                }))
                .filter(
                    row =>
                        row.task_type === StrategyTaskType.FocusSku ||
                        row.task_type === StrategyTaskType.GroupSku,
                ),
        [data?.task_priorities],
    );

    return {
        data: mappedPriorities || [],
        isLoading,
        error,
    };
};

export const usePocRecommendations = ({ strategyId, accountId, useCase }) => {
    const { user, environment } = useAppContext();
    const { skuInfoData, skuInfoLoading, skuInfoError } = useSkuInfo();
    const { data, isLoading, error } = useQuery({
        queryKey: ['Recommendations', strategyId, accountId, useCase],
        queryFn: () => getRecommendations(user?.token, strategyId, useCase, accountId, environment),
        enabled: !!strategyId && !!accountId && !!useCase,
    });

    const mappedData = data?.exported_recs?.map(item => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { full_name, pid, bees_category, brand_name } = skuInfoData.find(
            sku => sku.pid === item.product_id.replace('-EA', ''),
        );
        return {
            ...item,
            pid,
            full_name,
            brand_name,
            bees_category,
        };
    });

    return {
        recommendations: (mappedData ?? []) as Recommendation[],
        recommendationsLoading: isLoading || skuInfoLoading,
        recommendationsError: error || skuInfoError,
    };
};

export const useStrategyOverviewList = () => {
    const { logOut, user, environment } = useAppContext();
    const client = useStore(state => state.client);
    const country = useStore(state => state.country);
    const demoMode = useStore(state => state.demoMode);
    const { data, isLoading, error, refetch, isRefetching } = useQuery({
        queryKey: ['StrategyListOverview', client, country],
        queryFn: () => listOverview(user?.token, client, country, environment, logOut),
        enabled: !!client,
    });
    const dataWithExtraCalculations = useMemo(() => {
        const strategies = data?.strategies?.map(addAlgoSellingStats);
        let formattedPocLocations = formatStrategyOverviewPocLocations(data?.poc_locations);

        if (demoMode) {
            const nearbyMexicoStores = buildNearbyMexicoStores();
            formattedPocLocations = formattedPocLocations?.map(poc =>
                mapStoreToDemoLocation(poc, nearbyMexicoStores),
            );
        }

        return {
            strategies,
            formattedPocLocations,
        };
    }, [data, demoMode]);

    return {
        strategyOverviewListData: dataWithExtraCalculations,
        strategyOverviewListLoading: isLoading,
        strategyOverviewListError: error,
        strategyOverviewListRefetch: refetch,
        strategyOverviewListIsRefetching: isRefetching,
    };
};

export const useStrategyDetails = ({
    strategyId,
    enabled = true,
}: {
    strategyId: number;
    enabled?: boolean;
}) => {
    const { user, environment } = useAppContext();
    const client = useStore(state => state.client);
    const { data, isLoading, error } = useQuery({
        queryKey: ['StrategyDetails', strategyId],
        queryFn: () => getStrategyById(user?.token, strategyId?.toString(), client, environment),
        enabled,
    });

    return {
        strategy: data?.strategy,
        strategyLoading: isLoading,
        strategyError: error,
    };
};

export const useSkuGroups = ({ active = false } = {}) => {
    const { user, environment } = useAppContext();
    const demoMode = useStore(state => state.demoMode);
    const client = useStore(state => state.client);

    const { data, isLoading, error, refetch } = useQuery({
        queryKey: ['ListGroupSkus', environment, active, client],
        queryFn: () => listSkuGroups(user?.token, active, environment, client),
        enabled: true,
        refetchOnWindowFocus: false,
    });

    const sortedGroups = useMemo(
        () =>
            data?.group_skus
                ?.map((group, i) => {
                    if (demoMode) {
                        return {
                            ...group,
                            group_sku_name: `Group ${i + 1}`,
                        };
                    }
                    return group;
                })
                .sort((a, b) => b.id - a.id) || [],
        [data?.group_skus, demoMode],
    );

    return {
        skuGroups: sortedGroups,
        skuGroupsLoading: isLoading,
        skuGroupsError: error,
        skuGroupsRefetch: refetch,
    };
};

export const useAllSkuGroups = () => {
    const {
        skuGroups: activeSkuGroups,
        skuGroupsLoading: skuGroupsActiveLoading,
        skuGroupsRefetch: activeSkuGroupsRefetch,
    } = useSkuGroups({ active: true });

    const {
        skuGroups: inactiveSkuGroups,
        skuGroupsLoading,
        skuGroupsRefetch,
    } = useSkuGroups({ active: false });
    const refetchBoth = () => {
        activeSkuGroupsRefetch();
        skuGroupsRefetch();
    };
    const allSkuGroups = useMemo(
        () => [...activeSkuGroups, ...inactiveSkuGroups],
        [activeSkuGroups, inactiveSkuGroups],
    );

    return {
        activeSkuGroups,
        inactiveSkuGroups,
        allSkuGroups,
        skuGroupsLoading: skuGroupsActiveLoading || skuGroupsLoading,
        refetchBoth,
    };
};

export const useAvailableExclusions = ({ strategyId }: { strategyId: string }) => {
    const { user, environment } = useAppContext();

    const { data, isLoading, error } = useQuery({
        queryKey: ['AvailableExclusions', strategyId],
        queryFn: () => getAvailableExclusions(user?.token, strategyId, environment),
        enabled: true,
    });
    return {
        availableExclusions: data?.channels || [],
        availableExclusionsLoading: isLoading,
        availableExclusionsError: error,
    };
};

export const useStrategyDataWithFocusSkuGroups = ({
    strategyId,
    queryOptions,
}: {
    strategyId: string;
    queryOptions?: {
        keepPreviousData?: boolean;
        staleTime?: number;
    };
}) => {
    const { user, environment } = useAppContext();
    const { keepPreviousData: keepPreviousDataParam = true, staleTime = CACHE_MAX_DURATION / 2 } =
        queryOptions || {};

    const { skuInfoData, skuInfoLoading } = useSkuInfo();
    const { allSkuGroups: skuGroups, skuGroupsLoading } = useAllSkuGroups();

    const { data, isLoading, isFetching, error, refetch } = useQuery({
        queryKey: ['StrategyDataWithGroups', strategyId],
        queryFn: () => getStrategyDataAlt(user?.token, strategyId, environment),
        placeholderData: keepPreviousDataParam ? keepPreviousData : undefined,
        staleTime,
        enabled: !!user?.token && !!strategyId,
    });

    const client = useStore(state => state.client);
    const demoMode = useStore(state => state.demoMode);
    const name = useMemo(
        () =>
            demoMode
                ? buildDemoStrategyName(
                      data?.strategy?.is_control,
                      data?.strategy?.goal,
                      data?.strategy?.start_date,
                  )
                : data?.strategy?.name,
        [data?.strategy, demoMode],
    );

    const groups = data?.strategy?.group_skus;
    const focusSkus = data?.strategy?.focus_skus;
    const exclusions = data?.strategy?.exclusions;

    const { totalFocusProducts, focusSkusFullName, mappedGroups } = mapSkusToList({
        skuGroups,
        focusSkus,
        groups,
        skuInfoData,
    });

    const allFocusSkuPids = useMemo(() => {
        const focusSkuPids = focusSkusFullName?.map(sku => sku.sku);
        const groupSkuPids = mappedGroups.reduce((acc, curr) => {
            acc.push(...(curr.skus || []));
            return acc;
        }, []);
        return new Set([...focusSkuPids, ...groupSkuPids]);
    }, [focusSkusFullName, mappedGroups]);

    const remainingOtherSkus = skuInfoData
        .filter(sku => sku.pid && !allFocusSkuPids.has(sku.pid), [])
        .map(sku => sku.full_name);

    const allFocusSkuGroupSkuKeys = useMemo(
        () => [
            ...Object.keys(data?.strategy?.group_skus || {}),
            ...Object.keys(data?.strategy?.focus_skus || {}),
        ],
        [data?.strategy],
    );

    const skuGroupFullNameMap = useMemo(
        () =>
            allFocusSkuGroupSkuKeys.reduce((acc, key) => {
                const foundFocusSku = focusSkusFullName.find(sku => sku.sku === key)?.full_name;
                const { name: skuName } = parseSkuForClient(foundFocusSku, client, demoMode);
                acc[key] = skuName || key;
                return acc;
            }, {}),
        [allFocusSkuGroupSkuKeys, client, demoMode, focusSkusFullName],
    );

    const skuGroupSubheadingDataMap: Record<string, string | string[]> = useMemo(
        () =>
            allFocusSkuGroupSkuKeys.reduce((acc, key) => {
                const foundGroup = mappedGroups.find(group => group?.id?.toString() === key);
                const mappedKey = foundGroup ? foundGroup.group_sku_name : key;
                acc[mappedKey] = foundGroup ? foundGroup.skus_full_name : key;
                return acc;
            }, {}),
        [allFocusSkuGroupSkuKeys, mappedGroups],
    );

    return {
        strategy: data?.strategy,
        totalFocusProducts,
        skuGroupFullNameMap,
        skuGroupSubheadingDataMap,
        focusSkusFullName,
        mappedGroups,
        strategySkuGroupsLoading: isLoading || isFetching || skuGroupsLoading || skuInfoLoading,
        strategySkuGroupsError: error,
        remainingOtherSkus,
        exclusions,
        name,
        isStrategyLoading: isLoading,
        refetchStrategy: refetch,
        skuGroups,
    };
};

export const useCustomersInfo = ({ pocFilters, enabled }) => {
    const { user, environment } = useAppContext();
    const client = useStore(state => state.client);
    const country = useStore(state => state.country);
    const demoMode = useStore(state => state.demoMode);

    const { data, isLoading, error } = useQuery({
        queryKey: ['AccountsInfo', client, country, environment, JSON.stringify(pocFilters)],
        queryFn: () => getAccountsInfo(user?.token, client, country, environment, pocFilters),
        enabled,
    });

    const mappedData: Account[] = useMemo(() => {
        if (demoMode) {
            const nearbyMexicoStores = buildNearbyMexicoStores();
            return data?.accounts.map(poc => mapStoreToDemoLocation(poc, nearbyMexicoStores));
        }
        return data?.accounts;
    }, [data, demoMode]);

    return {
        customersInfoData: mappedData || [],
        customersInfoLoading: isLoading,
        customersInfoError: error,
    };
};

export const useAllPocs = () => {
    const demoMode = useStore(state => state.demoMode);

    // TODO: should this be all pocs in a strategy? which endpoint can do that
    const { customersInfoData, customersInfoLoading, customersInfoError } = useCustomersInfo({
        pocFilters: {},
        enabled: true,
    });

    const mappedData = useMemo(() => {
        if (demoMode) {
            const nearbyMexicoStores = buildNearbyMexicoStores();
            return customersInfoData?.map(poc => mapStoreToDemoLocation(poc, nearbyMexicoStores));
        }
        return customersInfoData;
    }, [customersInfoData, demoMode]);

    const pocMap: Record<string, Account> = mappedData.reduce((acc, curr) => {
        acc[curr.vendor_accountid] = curr;
        return acc;
    }, {});

    return {
        pocMap,
        pocMapLoading: customersInfoLoading,
        pocMapError: customersInfoError,
    };
};

export const useUpdateStrategy = () => {
    const { user, environment } = useAppContext();
    const {
        mutateAsync,
        error: updateStrategyError,
        isPending,
    } = useMutation({
        mutationKey: ['update-strategy'],
        mutationFn: ({
            strategyId,
            strategyParam,
        }: {
            strategyId: string;
            strategyParam: Strategy;
        }) => updateStrategy(user?.token, strategyId, strategyParam, environment),
    });
    return {
        mutateAsync,
        isLoading: isPending,
        updateStrategyError,
    };
};

export const useScheduleStrategy = () => {
    const { user, environment } = useAppContext();
    const {
        mutateAsync: schedule,
        error: scheduleStrategyError,
        isPending,
    } = useMutation({
        mutationKey: ['schedule-strategy'],
        mutationFn: ({ strategyId }: { strategyId: string }) =>
            scheduleStrategy(user?.token, strategyId, environment),
    });
    return {
        schedule,
        isLoading: isPending,
        scheduleStrategyError,
    };
};

export const useUnscheduleStrategy = () => {
    const { user, environment } = useAppContext();
    const {
        mutateAsync: unschedule,
        error: unScheduleStrategyError,
        isPending,
    } = useMutation({
        mutationKey: ['schedule-strategy'],
        mutationFn: ({ strategyId }: { strategyId: string }) =>
            unscheduleStrategy(user?.token, strategyId, environment),
    });
    return {
        unschedule,
        isLoading: isPending,
        unScheduleStrategyError,
    };
};

export const useRegenerateTasks = () => {
    const { user, environment } = useAppContext();
    const {
        mutateAsync: regenerate,
        error: regenerateTasksError,
        isPending,
    } = useMutation({
        mutationKey: ['regenerate-tasks'],
        mutationFn: ({ strategyId }: { strategyId: string }) =>
            regenerateTasks(user?.token, strategyId, environment, user.email, user.firstName),
    });
    return {
        regenerate,
        isLoading: isPending,
        regenerateTasksError,
    };
};

export const useUpdateSkuPriorities = () => {
    const { t } = useClientTranslation();
    const { user, environment } = useAppContext();
    const router = useRouter();
    const {
        mutateAsync,
        error: updateSkuPrioritesError,
        isPending,
    } = useMutation({
        mutationKey: ['update-sku-priorities'],
        mutationFn: ({
            strategyId,
            skuPriorityChanges,
            strategyTaskPercentage,
            autoOptimize,
        }: {
            strategyId: string;
            skuPriorityChanges: SkuPriority[];
            strategyTaskPercentage: number;
            autoOptimize: boolean;
        }) =>
            updateSkuPriorities(
                user?.token,
                strategyId,
                skuPriorityChanges,
                strategyTaskPercentage,
                autoOptimize,
                environment,
            )
                .then(() =>
                    regenerateTasks(
                        user?.token,
                        strategyId,
                        environment,
                        user.email,
                        user.firstName,
                    ),
                )
                .then(async () => {
                    router.push(HelmRoutes.HOME(router.query));
                    setTimeout(() => {
                        // hack so toast doesn't appear before redirecting
                        showNotification({
                            message: `${t('generating preview')}!`,
                            color: 'green',
                            icon: <ExclamationMark />,
                        });
                    }, 200);
                }),
    });
    return {
        mutateAsync,
        isLoading: isPending,
        updateSkuPrioritesError,
    };
};

export const useControlsTaskSummary = ({ strategyId }) => {
    const { user, environment } = useAppContext();
    const { data, isLoading, error } = useQuery({
        queryKey: ['ControlsTaskSummary', strategyId],
        queryFn: () => getControlsTaskSummary(user?.token, strategyId, environment),
    });

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const sku_distribution = useMemo(() => data?.sku_summary?.map(mapToFormat) || [], [data]);

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const potential_counts = useMemo(() => {
        const mappedPotentials = data?.potential_summary?.reduce((acc, curr) => {
            acc[curr.potential] = curr.number_of_tasks;
            return acc;
        }, {});
        return {
            baseline: mappedPotentials,
            current: mappedPotentials,
        };
    }, [data]);

    return {
        data: {
            sku_distribution,
            potential_counts,
        },
        isLoading,
        error,
    };
};

export const useTasksForSku = ({ strategyId, sku }) => {
    const { user, environment } = useAppContext();
    const { pocMap, pocMapLoading } = useAllPocs();

    const { data, isLoading, error } = useQuery({
        queryKey: ['SkuTasksBreakdown', strategyId, sku],
        queryFn: () => getSkusTasksBreakdown(user?.token, strategyId, sku, environment),
        enabled: Boolean(strategyId && sku),
    });

    const reformattedData = useMemo(
        () =>
            (data?.tasks || [])?.map(item => ({
                ...item,
                potential: item.predicted_potential_category,
                skus: item.skus?.map(s => parseInt(s, 10)),
            })),
        [data?.tasks],
    );

    const mappedData = useMemo(() => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        // const { current_tasks, baseline_tasks } = data || {};
        const currentTasks = reformattedData?.map(task => ({
            ...task,
            ...(pocMap[task.vendor_accountid] || {}),
        }));
        const baselineTasks = reformattedData?.map(task => ({
            ...task,
            ...(pocMap[task.vendor_accountid] || {}),
        }));
        return {
            currentTasks,
            baselineTasks,
        };
    }, [pocMap, reformattedData]);

    return {
        data: mappedData,
        isLoading: isLoading || pocMapLoading,
        error,
    };
};

export const useCreateNewSkuGroup = () => {
    const { user, environment } = useAppContext();
    const { mutateAsync, error, isPending } = useMutation({
        mutationKey: ['create-new-sku-group'],
        mutationFn: ({
            skuGroup,
        }: {
            skuGroup: {
                skus: string[];
                client: ClientOptions;
                id?: number;
                skus_full_name?: string[];
                task_name: string;
                group_sku_name: string;
            };
        }) => createNewSkuGroup(user?.token, skuGroup, environment),
    });

    return {
        createNewSkuGroupMutateAsync: mutateAsync,
        createNewSkuGroupIsLoading: isPending,
        createNewSkuGroupError: error,
    };
};

export const useDeleteStrategy = (props?: DeleteStrategyPropsType) => {
    const { user, environment } = useAppContext();
    const { onSuccess, onError, onMutate, onSettled } = props;
    const { mutateAsync, error, isPending } = useMutation({
        mutationKey: ['delete-strategy'],
        mutationFn: ({ id }: { id: number }) => deleteStrategy(user.token, id, environment),
        onSuccess,
        onError,
        onMutate,
        onSettled,
    });

    return {
        deleteStrategyMutateAsync: mutateAsync,
        deleteStrategyIsLoading: isPending,
        deleteStrategyError: error,
    };
};

export const useGetFilterValues = ({
    pocFilters,
    productFilters,
}: {
    pocFilters: string[];
    productFilters: string[];
}) => {
    const { user, environment } = useAppContext();
    const client = useStore(state => state.client);
    const country = useStore(state => state.country);
    const { error, isPending, data } = useQuery({
        queryKey: ['get-filter-values'],
        queryFn: () =>
            getFilterValues(user?.token, environment, {
                client,
                country,
                poc_filters: pocFilters,
                product_filters: productFilters,
            }),
    });

    return {
        strategyFilterValuesData: data,
        strategyFilterValuesLoading: isPending,
        strategyFilterValuesError: error,
    };
};

export const useDailySummary = ({
    strategyId,
    filterValues,
    startDate,
    endDate,
    statsMode,
    totalPocs,
}) => {
    const { user, environment } = useAppContext();
    const demoMode = useStore(state => state.demoMode);
    const client = useStore(state => state.client);

    const { pocFilters, productFilters } = useMemo(
        () => splitFilters(filterValues),
        [filterValues],
    );

    const { data, isLoading, error } = useQuery({
        queryKey: [
            'DailySummary',
            strategyId,
            filterValues,
            startDate,
            endDate,
            JSON.stringify(filterValues),
        ],
        queryFn: () =>
            getDailySummary(
                user?.token,
                client,
                environment,
                strategyId,
                pocFilters,
                productFilters,
                startDate,
                endDate,
            ),
        enabled: startDate && moment(startDate).isValid(),
    });

    const mappedData = useMemo(
        () =>
            data?.daily_summary?.map(d => {
                if (demoMode) {
                    return {
                        ...d,
                        revenue: (d?.revenue || 0) * DEMO_SCALING,
                        mix: d.mix * DEMO_SCALING,
                    };
                }
                return d;
            }),
        [data?.daily_summary, demoMode],
    );

    return {
        data: calculateCumulativeSums(
            fillMissingDates(mappedData || [], startDate, endDate),
            statsMode,
            totalPocs,
        ),
        isLoading,
        error,
    };
};

export const useStrategyTaskBreakdown = ({
    strategyId,
    filterValues,
    startDate,
    endDate,
    gcTime,
}: {
    startDate: moment.Moment;
    endDate: moment.Moment;
    strategyId: string;
    filterValues: Record<string, string[]>;
    gcTime?: number;
}) => {
    const { user, environment } = useAppContext();
    const { pocFilters } = useMemo(() => splitFilters(filterValues), [filterValues]);

    const { data, isLoading, error } = useQuery({
        queryKey: [
            'StrategyTaskMetrics',
            strategyId,
            JSON.stringify(pocFilters),
            startDate,
            endDate,
        ],
        queryFn: () =>
            getStrategyTasksBreakdown(
                user?.token,
                startDate,
                endDate,
                strategyId,
                pocFilters,
                environment,
            ),
        enabled: startDate && moment(startDate).isValid(),
        gcTime,
    });

    const totalPOCSsBDRVisited = useMemo(() => {
        const accounts = new Map();

        data?.forEach(client => {
            if (client.visit_duration_seconds > 0) {
                accounts.set(client.vendor_accountid, true);
            }
        });

        return accounts.size;
    }, [data]);

    return {
        tasksBreakdown: data || [],
        totalPOCSsBDRVisited,
        isLoading,
        error,
    };
};

export const useStrategyOrdersMetrics = ({ strategyId, filterValues, startDate, endDate }) => {
    const { user, environment } = useAppContext();
    const client = useStore(state => state.client);

    const { pocFilters, productFilters } = useMemo(
        () => splitFilters(filterValues),
        [filterValues],
    );

    const { data, isLoading, error } = useQuery({
        queryKey: ['OrdersMetrics', strategyId, filterValues, startDate, endDate],
        queryFn: () =>
            getStrategyOrdersMetrics(
                user?.token,
                environment,
                strategyId,
                pocFilters,
                productFilters,
                startDate,
                endDate,
                client,
            ),
        enabled: startDate && moment(startDate).isValid(),
    });

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { average_order_value, num_orders } = data || {};

    return {
        data: {
            averageOrderValue: average_order_value,
            numOrders: num_orders,
        },
        isLoading,
        error,
    };
};

export const useStrategyForecasts = ({ strategyId, startDate, endDate }) => {
    const demoMode = useStore(state => state.demoMode);
    const { user, environment } = useAppContext();
    const { data, isLoading, error } = useQuery({
        queryKey: ['StrategyForecast', strategyId, startDate, endDate],
        queryFn: () =>
            getStrategyForecast(user?.token, environment, strategyId, startDate, endDate),
        enabled: false,
    });
    const mappedData = useMemo(
        () =>
            data?.forecast?.map(convertProjectionData).map(d => {
                if (demoMode) {
                    return {
                        ...d,
                        hectaLitersDaily: d.hectaLitersDaily * DEMO_SCALING,
                        hectaLitersCumulative: d.hectaLitersCumulative * DEMO_SCALING,
                        revenueDaily: d.revenueDaily * DEMO_SCALING,
                        revenueCumulative: d.revenueCumulative * DEMO_SCALING,
                    };
                }
                return d;
            }),
        [data?.forecast, demoMode],
    );
    return {
        data: mappedData || [],
        isLoading,
        error,
    };
};

export const useAggregatedMetrics = ({
    strategyId,
    filterValues,
    startDate,
    endDate,
    gcTime,
}: {
    strategyId: string;
    filterValues: Record<string, string[]>;
    startDate: moment.Moment;
    endDate: moment.Moment;
    gcTime?: number;
}) => {
    const { user, environment } = useAppContext();
    const client = useStore(state => state.client);

    const { pocFilters, productFilters } = useMemo(
        () => splitFilters(filterValues),
        [filterValues],
    );

    const { data, isLoading, error } = useQuery({
        queryKey: ['AggregatedMetrics', strategyId, filterValues, startDate, endDate],
        queryFn: () =>
            getAggregatedMetrics(
                user?.token,
                client,
                environment,
                strategyId,
                pocFilters,
                productFilters,
                startDate,
                endDate,
            ),
        enabled: startDate && moment(startDate).isValid(),
        gcTime,
    });

    return {
        data: data || [],
        isLoading,
        error,
    };
};

export const useStrategyBreakdownWithComparisons = ({
    filterValues,
    strategyId,
    metric,
    groupBy,
    startDate,
    endDate,
    statsMode,
    summaryOption,
}) => {
    const { user, environment } = useAppContext();
    const { pocFilters, productFilters } = splitFilters(filterValues);

    const dateRanges = [
        { startDate, endDate },
        {
            startDate: seasonalDiffWithDayMatch(startDate, 1, 'months'),
            endDate: seasonalDiffWithDayMatch(endDate, 1, 'months'),
        },
        {
            startDate: seasonalDiffWithDayMatch(startDate, 2, 'months'),
            endDate: seasonalDiffWithDayMatch(endDate, 2, 'months'),
        },
        {
            startDate: seasonalDiffWithDayMatch(startDate, 3, 'months'),
            endDate: seasonalDiffWithDayMatch(endDate, 3, 'months'),
        },
    ];

    const [currentStrategy, lastMonth, twoMonthsAgo, threeMonthsAgo] = useQueries({
        queries: dateRanges.map(dates =>
            createQuery(
                user.token,
                dates.startDate,
                dates.endDate,
                strategyId,
                { pocFilters, productFilters },
                metric,
                groupBy,
                environment,
                summaryOption,
            ),
        ),
    });

    const isLoading = useMemo(
        () =>
            currentStrategy.isLoading ||
            lastMonth.isLoading ||
            twoMonthsAgo.isLoading ||
            threeMonthsAgo.isLoading,
        [
            currentStrategy.isLoading,
            lastMonth.isLoading,
            threeMonthsAgo.isLoading,
            twoMonthsAgo.isLoading,
        ],
    );
    const error = useMemo(
        () =>
            currentStrategy.error || lastMonth.error || twoMonthsAgo.error || threeMonthsAgo.error,
        [currentStrategy.error, lastMonth.error, threeMonthsAgo.error, twoMonthsAgo.error],
    );
    const tableRows = useMemo(() => {
        const currentData = currentStrategy.data?.poc_breakdown || [];
        const lastMonthData = lastMonth.data?.poc_breakdown || [];
        const twoMonthsAgoData = twoMonthsAgo.data?.poc_breakdown || [];
        const threeMonthsAgoData = threeMonthsAgo.data?.poc_breakdown || [];

        return buildTableRows(
            currentData,
            lastMonthData,
            twoMonthsAgoData,
            threeMonthsAgoData,
            statsMode,
        );
    }, [
        currentStrategy.data?.poc_breakdown,
        lastMonth.data?.poc_breakdown,
        statsMode,
        threeMonthsAgo.data?.poc_breakdown,
        twoMonthsAgo.data?.poc_breakdown,
    ]);

    return { data: tableRows, isLoading, error };
};

export const useStrategyAccounts = ({
    strategyId,
    gcTime,
}: {
    strategyId: string;
    gcTime?: number;
}) => {
    const { user, environment } = useAppContext();
    const { data, isLoading, error } = useQuery({
        queryKey: ['StrategyAccounts', strategyId],
        queryFn: () => getStrategyAccounts(user?.token, environment, strategyId),
        enabled: !!user?.token && !!strategyId,
        gcTime,
    });

    return {
        strategyAccounts: data || [],
        strategyAccountsLoading: isLoading,
        strategyAccountsError: error,
    };
};

export const useStrategyAllPocsForFilters = ({
    strategyId,
    filterValues,
    gcTime,
}: {
    strategyId: string;
    filterValues: Record<string, string[]>;
    gcTime?: number;
}) => {
    const { strategyAccounts, strategyAccountsLoading, strategyAccountsError } =
        useStrategyAccounts({ strategyId, gcTime });
    const filteredAccounts = useMemo(() => {
        if (!strategyAccounts) return [];

        return strategyAccounts.filter(account =>
            Object.entries(filterValues).every(([key, values]) =>
                values.some(
                    value => account[key] && account[key].toLowerCase() === value.toLowerCase(),
                ),
            ),
        );
    }, [strategyAccounts, filterValues]);

    return {
        strategyFilteredAccounts: filteredAccounts || [],
        strategyFilteredAccountsLoading: strategyAccountsLoading,
        strategyFilteredAccountsError: strategyAccountsError,
    };
};

export const usePocExplorerQuery = ({ vendorAccountId }: { vendorAccountId: string }) => {
    const { user, environment } = useAppContext();
    const client = useStore(state => state.client);
    const country = useStore(state => state.country);
    const { data, isLoading, refetch, error } = useQuery({
        queryKey: ['PocExplorer', client, country, vendorAccountId],
        queryFn: () =>
            getPocExplorer(user?.token, environment, {
                client,
                country,
                vendor_accountid: vendorAccountId.trim(),
            }),
        enabled: !!vendorAccountId,
        retry: (failureCount, err) => false,
    });

    return {
        pocExplorerData: data,
        pocExplorerIsLoading: isLoading,
        pocExplorerError: error,
        pocExplorerRefetch: refetch,
    };
};

export const usePocExplorerSummary = () => {
    const { user, environment } = useAppContext();
    const client = useStore(state => state.client);
    const country = useStore(state => state.country);
    const { data, isLoading, error } = useQuery({
        queryKey: ['PocExplorerSummary', client, country],
        queryFn: () => getPocExplorerSummary(user?.token, environment, client, country),
        enabled: !!user?.token,
    });

    return {
        pocExplorerSummaryData: data,
        pocExplorerSummaryIsLoading: isLoading,
        pocExplorerSummaryError: error,
    };
};

export const useStrategyChannels = () => {
    const { user, environment } = useAppContext();
    const client = useStore(state => state.client);
    const country = useStore(state => state.country);

    const { data, isLoading, error } = useQuery({
        queryKey: ['StrategyChannels'],
        queryFn: () => getStrategyChannels(user?.token, environment, client, country),
        enabled: !!user?.token,
    });

    return {
        strategyChannels: data?.channels || [],
        strategyChannelsLoading: isLoading,
        strategyChannelsError: error,
    };
};
