import { Spinner } from './spinner';

export const SmallSpinner = () => (
    <div className="w-6">
        <Spinner size={16} />
    </div>
);

export const SmallSpinnerWithPadding = () => (
    <div className="flex size-[20px] items-center justify-center">
        <SmallSpinner />
    </div>
);
