import cx from 'classnames';
import { customSortDistinctSkusBuckets } from '../../../../../hooks/helm/useMetricsDistinctValues';
import { ClientOptions } from '../../../util/config';
import { channelDisplay, channelTypeDisplay } from '../../../util/util';
import { ChartMetric, SkuInfo, TableRow } from '../../../util/types';
import { formatNumber } from '../../../../../utils/numberFormatHelpers';
import {
    BreakdownRow,
    BreakdownTableSortByKeys,
    BreakdownTableSortConfig,
    MetricOptionType,
} from '../types';

export const metricOptions: MetricOptionType[] = [
    { label: 'Revenue', value: ChartMetric.Revenue },
    // { label: 'Suggested Order - Order count (will change)', value: 'quick_order_order_count'}
    // 'Coverage',
    // 'Range',
    // 'Force - Minutes spent',
    // 'Force - Tasks completed',
    // 'Customer - Overall - Ordered %',
    // 'Customer - Suggested Order - Orderd %',
    // 'Customer - Upsell - Ordered %',
    // 'Customer - Forgotten Items - Ordered %',
    // 'Customer - Deal Sort - Ordered %',
    // 'Customer - Top Deals - Ordered %',
    // 'Push Nofitications - Ordered %',
    // 'In-App - Ordered %',
];

export const fillWithZeroValue = (value: number) => {
    if (
        value === undefined ||
        value === null ||
        value === Infinity ||
        value === -Infinity ||
        isNaN(value)
    ) {
        return 0;
    }
    return value;
};

export const axisOptions = [
    { label: 'Product', value: 'pid' },
    { label: 'Brand', value: 'brand_name' },
    { label: 'Category', value: 'bees_category' },
    { label: 'Region', value: 'customer_delivery_region_name' },
    { label: 'Channel', value: 'channel' },
    // { label: 'Channel Type', value: 'channel_type' },
    // { label: '# of distinct SKUs', value: 'sku_coverage' },
    { label: 'Size', value: 'size' },
    { label: 'Distributor', value: 'distributor_name' },
];

export const disabledOptions = ['Push Nofitications - Ordered %', 'In-App - Ordered %'];

export const axisDisplay = (value, type) => {
    if (type === 'customer_delivery_region_name') {
        return value;
    }
    if (type === 'channel') {
        return channelDisplay(value);
    }
    return value;
};

const delimiter = '[key]';
const buildKey = (v, h) => `${v}${delimiter}${h}`;
const extractFromKey = string => {
    const [v, h] = string.split(delimiter);
    return { v, h };
};

export const buildChartData = (
    currentYearResults: Record<string, string | number>[],
    lastYearResults,
    vertical,
    horizontal,
    metricValue,
) => {
    const xLabels = [...new Set(currentYearResults.map(row => row[horizontal]))].sort(
        customSortDistinctSkusBuckets,
    );
    const yLabels = [...new Set(currentYearResults.map(row => row[vertical]))].sort(
        customSortDistinctSkusBuckets,
    );

    const lastYearMap = new Map(
        lastYearResults.map(item => [
            buildKey(item[vertical], item[horizontal]), // TODO: make a utility to build a key? it's duplicated
            item,
        ]),
    );

    const currentYearMap = new Map(
        currentYearResults.map(item => [buildKey(item[vertical], item[horizontal]), item]),
    );

    const combinedKeys = new Set(yLabels.flatMap(v => xLabels.map(h => buildKey(v, h))));

    const chartData = Array.from(combinedKeys).map(key => {
        const { v, h } = extractFromKey(key);
        const currentItem = currentYearMap.get(key) || { [metricValue]: 0, count: 0 };
        const lastYearItem = lastYearMap.get(key) || { [metricValue]: 0, count: 0 };
        const diff =
            lastYearItem[metricValue] !== 0
                ? (currentItem[metricValue] as number) - lastYearItem[metricValue]
                : null;
        const percentDiff =
            lastYearItem[metricValue] !== 0 ? diff / lastYearItem[metricValue] : null;

        return {
            x: h,
            y: v,
            count: currentItem.count,
            [metricValue]: currentItem[metricValue],
            [`${metricValue}_diff`]: diff,
            [`${metricValue}_percent_diff`]: percentDiff,
        };
    });
    return { chartData };
};

export function parseSkuFullNameABI(input = '') {
    const result = {} as any;

    const skuMatch = input?.match(/^\d+/);
    const packMatch = input?.match(/\[(.*?)\]/);
    const nameMatch = input?.match(/-\s(.*?)\s\[/);

    if (skuMatch) {
        result.sku = skuMatch[0];
    }

    if (packMatch) {
        result.pack = packMatch[1];
    }

    if (nameMatch) {
        result.name = nameMatch[1];
    }

    return result;
}

export function parseSkuFullNameNestle(input) {
    const [sku, name] = input ? input.split(' - ') : '';

    return {
        sku,
        name,
    };
}

function getStringHash(input: string = ''): number {
    let hash = 0;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < input?.length; i++) {
        const char = input.charCodeAt(i);
        // eslint-disable-next-line no-bitwise
        hash = (hash << 5) - hash + char;
        // eslint-disable-next-line operator-assignment, no-bitwise
        hash = hash & hash; // Convert to 32-bit integer
    }

    // Normalize the hash to a value between 501 and 10000
    return 501 + (hash % 9499);
}

export function parseSkuForClient(input, client: ClientOptions, demoMode = false) {
    if (demoMode) {
        return {
            sku: getStringHash(input),
            name: input,
        };
    }
    if (client === ClientOptions.AlphaC) {
        return parseSkuFullNameABI(input);
    }
    return parseSkuFullNameNestle(input);
}

export const getCommonSortCaretClasses = (sortBy: string, sortConfig: BreakdownTableSortConfig) =>
    cx('invisible group-hover:visible', {
        '!visible': sortBy === sortConfig.key || sortConfig.key.includes(sortBy),
    });

export const getSortByKeyForTwoKeys = (
    { direction, key }: BreakdownTableSortConfig,
    metricKey: BreakdownTableSortByKeys,
    percentKey: BreakdownTableSortByKeys,
) => {
    if (direction === 'desc' && key === percentKey) {
        return percentKey;
    }
    if (direction === 'asc' && key === percentKey) {
        return metricKey;
    }
    if (direction === 'desc' && key === metricKey) {
        return metricKey;
    }
    return percentKey;
};

export const formatMetric = (diff: number, metric: ChartMetric) => {
    if (metric === ChartMetric.Revenue) {
        return formatNumber(diff, '$0.00a');
    }
    return null;
};

export const getPidProductMap = (skuInfoData: SkuInfo[]) =>
    skuInfoData.reduce(
        (acc, sku) => {
            acc[sku.pid] = sku.full_name;
            return acc;
        },
        {} as Record<string, string>,
    );

export const getSegmentDisplay = (
    segment: string,
    vertical: string,
    skuInfoData: SkuInfo[],
    t: any,
) => {
    if (vertical === 'customer_delivery_region_name') {
        return segment === 'UNKNOWN' ? t('unknown').toUpperCase() : segment;
    }
    if (vertical === 'channel') {
        return t(channelDisplay(segment));
    }
    if (vertical === 'channel_type') {
        return t(channelTypeDisplay(segment));
    }
    if (vertical === 'pid') {
        return getPidProductMap(skuInfoData)[segment] || segment;
    }
    if (vertical === 'brand_name') {
        return segment || t('unknown');
    }
    return segment;
};

export const formatBreakdownRows = ({
    breakdownRows,
    skuInfoData,
    vertical,
    client,
    t,
}: {
    skuInfoData: SkuInfo[];
    breakdownRows: TableRow[];
    vertical: string;
    client: ClientOptions;
    t: any;
}): BreakdownRow[] => {
    function extractName(input) {
        const nameRegex = /^[0-9]*\s*-\s*|^[0-9]*\s*-?\s*|[0-9]*\s*-\s*|[0-9]*\s*-?\s*/;

        const match = input.match(nameRegex);
        if (match) {
            return input.replace(match[0], '').trim();
        }
        return input.trim();
    }

    const getProductName = (segmentName: string) => {
        const segmentDisplayName = getSegmentDisplay(segmentName, vertical, skuInfoData, t) || '';

        if (vertical === 'pid') {
            const originalFullName = getPidProductMap(skuInfoData)[segmentName];
            const { sku, name } = parseSkuForClient(originalFullName, client);

            if (sku && name) {
                return name;
            }
            return segmentDisplayName;
        }

        return segmentDisplayName;
    };

    return breakdownRows.map(row => ({
        ...row,
        productName: extractName(getProductName(row.segmentName)),
        altStrategy_metric: fillWithZeroValue(row.comparisons[0].metricDiff),
        altStrategy_percent: fillWithZeroValue(row.comparisons[0].percentDiff),
        lastMonthCurrentStrategy_metric: fillWithZeroValue(row.comparisons[1].metricDiff),
        lastMonthCurrentStrategy_percent: fillWithZeroValue(row.comparisons[1].percentDiff),
        twoMonthsAgoCurrentStrategy_metric: fillWithZeroValue(row.comparisons[2]?.metricDiff),
        twoMonthsAgoCurrentStrategy_percent: fillWithZeroValue(row.comparisons[2]?.percentDiff),
        threeMonthsAgoCurrentStrategy_metric: fillWithZeroValue(row.comparisons[3]?.metricDiff),
        threeMonthsAgoCurrentStrategy_percent: fillWithZeroValue(row.comparisons[3]?.percentDiff),
        lastYearCurrentStrategy_metric: fillWithZeroValue(row.comparisons[4]?.metricDiff),
        lastYearCurrentStrategy_percent: fillWithZeroValue(row.comparisons[4]?.percentDiff),
    }));
};

export const getSortedBreakdownRows = ({
    sortConfig,
    breakdownRows,
    skuInfoData,
    vertical,
    client,
    t,
}: {
    sortConfig: BreakdownTableSortConfig;
    skuInfoData: SkuInfo[];
    breakdownRows: TableRow[];
    vertical: string;
    client: ClientOptions;
    t: any;
}) => {
    const { key, direction } = sortConfig;
    const formattedBreakdownRows = formatBreakdownRows({
        breakdownRows,
        vertical,
        client,
        skuInfoData,
        t,
    });
    const sorted = [...formattedBreakdownRows];

    sorted.sort((a, b) => {
        if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
        if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
        return 0;
    });

    return sorted;
};
