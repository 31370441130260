import moment from 'moment';
import getNeptuneUrl from '../../utils/getNeptuneUrl';
import { ClientOptions, CountryOptions, Environment } from '../util/config';
import {
    AccountsInfo,
    AddFocusSkuToStrategy,
    AddGroupSkuToStrategy,
    AggregatedMetric,
    ArchiveGroupSkuResponse,
    AutoOptimizePriorities,
    AvailableExclusionChannels,
    CreateGroupSkuResponse,
    DailySummaryType,
    DeleteStrategyResponse,
    ExclusionUpdates,
    FilterValuesResponseType,
    GetStrategy,
    LatestExportedRecs,
    ListGroupSkus,
    PocExplorerReponseType,
    PocExplorerSummaryReponseType,
    RegenerateTasksResponse,
    ScheduleStrategyResponse,
    SkuGroup,
    SkuTaskBreakdown,
    Strategy,
    StrategyAccount,
    StrategyBreakdownResponse,
    StrategyChannelsResponseType,
    StrategyDataAlt,
    StrategyForecast,
    StrategyOrdersMetrics,
    StrategyOverviewType,
    TaskGenerationStatus,
    TasksBreakdown,
    TaskSummary,
    UnArchiveGroupSkuResponse,
    UnScheduleStrategyResponse,
    UpdateExclusionsResponse,
    UpdateGroupSkuResponse,
    UpdateSkuPrioritiesResponse,
    UpdateStrategyResponse,
} from '../util/types';
import { SkuPriority } from '../pages/previewStrategy/types';
import { getSafeAPIResult, parseSafeJSON } from '../util/util';

export const getErrorMessage = (response: any, errorData: Error) =>
    `API returned an error: ${response.status} ${errorData.message || 'Unknown error'}`;

export const listStrategies = async (
    token: string | undefined,
    client: ClientOptions,
    env: Environment,
): Promise<{ strategies: Strategy[] }> => {
    const url = getNeptuneUrl(env, `strategy/list?client=${client}`);
    try {
        const response = await fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${token}`,
            },
        });

        if (!response.ok) {
            const errorData = await response.json();

            throw new Error(getErrorMessage(response, errorData));
        }

        return await response.json();
    } catch (error) {
        throw new Error(`Error fetching strategies: ${error.message}`);
    }
};

export const listOverview = async (
    token: string | undefined,
    client: ClientOptions,
    country: CountryOptions,
    env: Environment,
    logout: (token: string) => void,
): Promise<StrategyOverviewType> => {
    const url = getNeptuneUrl(env, `strategy/overview/${client}/${country}`);
    try {
        const response = await fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${token}`,
            },
        });

        if (response.status === 401) {
            logout(token);
        }

        if (!response.ok) {
            const errorData = await response.json(); // assuming the server sends back a JSON error message

            throw new Error(getErrorMessage(response, errorData));
        }

        return await response.json();
    } catch (error) {
        throw new Error(`Error fetching overview list: ${error.message}`);
    }
};

export const getSkuInfo = async (
    token: string | undefined,
    client: ClientOptions,
    env: Environment,
) => {
    const url = getNeptuneUrl(env, `ui/sku-info/${client}`);
    return fetch(url, {
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${token}`,
        },
    }).then(response => response.json());
};

export const getDailySummary = async (
    token: string | undefined,
    client: ClientOptions,
    env: Environment,
    strategyId: number,
    pocFilters: Record<string, string[]>,
    productFilters: Record<string, string[]>,
    startDate: string,
    endDate: string,
): Promise<DailySummaryType> => {
    const url = getNeptuneUrl(env, `strategy/daily-summary`);
    const body = {
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
        poc_filters: pocFilters,
        product_filters: productFilters,
        client,
        strategy_id: strategyId,
    };
    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
    }).then(response => response.json());
};

export const getStrategyBreakdown = async (
    token: string | undefined,
    startDate: string,
    endDate: string,
    strategyId: string,
    pocFilters: Record<string, string[]>,
    productFilters: Record<string, string[]>,
    metric: string,
    groupBy: string,
    environment: Environment,
): Promise<StrategyBreakdownResponse> => {
    const url = getNeptuneUrl(environment, `strategy/breakdown`);
    const body = {
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
        strategy_id: strategyId,
        poc_filters: pocFilters,
        product_filters: productFilters,
        metric,
        group_by: groupBy,
    };
    return fetch(url, {
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${token}`,
        },
        method: 'POST',
        body: JSON.stringify(body),
    })
        .then(response => response.text())
        .then(text => parseSafeJSON(text));
};

export const getStrategyForecast = async (
    token: string | undefined,
    environment: Environment,
    strategy_id: string,
    start_date: string,
    end_date: string,
): Promise<StrategyForecast> => {
    const url = new URL(getNeptuneUrl(environment, `strategy/strategy-forecast`));

    url.searchParams.append('strategy_id', strategy_id);
    url.searchParams.append('start_date', moment(start_date).format('YYYY-MM-DD'));
    url.searchParams.append('end_date', moment(end_date).format('YYYY-MM-DD'));

    return fetch(url.toString(), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${token}`,
        },
    }).then(response => response.json());
};

export const getAvailableDistributors = async (
    token: string | undefined,
    client: ClientOptions,
    env: Environment,
) => {
    const url = getNeptuneUrl(env, `ui/customer-distributors/${client}`);
    return fetch(url, {
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${token}`,
        },
    }).then(response => response.json());
};

// TODO: replace hack

export const getCustomersInfo = async (
    token: string | undefined,
    client: ClientOptions,
    env: Environment,
    pocFilters: Record<string, string[]>,
) => {
    const url = getNeptuneUrl(env, `ui/customers-info/`);
    const body = {
        poc_filters: pocFilters,
        client,
    };
    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
    })
        .then(response => response.text())
        .then(text => parseSafeJSON(text));
};

export const getAggregatedMetrics = async (
    token: string | undefined,
    client: ClientOptions,
    env: Environment,
    strategyId: string,
    pocFilters: Record<string, string[]>,
    productFilters: Record<string, string[]>,
    startDate: moment.Moment,
    endDate: moment.Moment,
): Promise<AggregatedMetric[]> => {
    const url = getNeptuneUrl(env, `strategy/aggregated-metrics-v2`);
    const body = {
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
        poc_filters: pocFilters,
        product_filters: productFilters,
        client,
        strategy_id: strategyId,
    };
    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${token}`,
        },

        body: JSON.stringify(body),
    })
        .then(response => response.text())
        .then(text => getSafeAPIResult<AggregatedMetric>(text));
};

export const getProductInfo = async (
    token: string | undefined,
    client: ClientOptions,
    country: CountryOptions,
    env: Environment,
) => {
    const url = getNeptuneUrl(env, `strategy/product-info/${client}/${country}`);
    return fetch(url, {
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${token}`,
        },
    }).then(response => response.json());
};

export const getAccountsInfo = async (
    token: string | undefined,
    client: ClientOptions,
    country: CountryOptions,
    env: Environment,
    pocFilters: Record<string, string[]> = {},
): Promise<AccountsInfo> => {
    const url = getNeptuneUrl(env, `strategy/accounts-info/`);
    const body = {
        poc_filters: pocFilters,
        client,
        country,
    };

    return fetch(url, {
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${token}`,
        },
        method: 'POST',
        body: JSON.stringify(body),
    })
        .then(response => response.text())
        .then(text => parseSafeJSON(text));
};

export const getAccountsDistributors = async (
    token: string | undefined,
    env: Environment,
    client: ClientOptions,
    country: CountryOptions,
) => {
    const url = getNeptuneUrl(env, `strategy/accounts-distributors/${client}/${country}`);
    return fetch(url, {
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${token}`,
        },
    }).then(response => response.json());
};

export const getStrategyAccounts = async (
    token: string | undefined,
    env: Environment,
    strategyId: string,
): Promise<StrategyAccount[]> => {
    const url = getNeptuneUrl(env, `strategy/strategy-accounts-v2/${strategyId}`);

    return fetch(url, {
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${token}`,
        },
    })
        .then(response => response.text())
        .then(text => getSafeAPIResult<StrategyAccount>(text));
};

export const getStrategyOrdersMetrics = async (
    token: string | undefined,
    env: Environment,
    strategyId: number,
    pocFilters: Record<string, string[]>,
    productFilters: Record<string, string[]>,
    startDate: string,
    endDate: string,
    client: ClientOptions,
): Promise<StrategyOrdersMetrics> => {
    const url = getNeptuneUrl(env, `strategy/orders-metrics`);
    const body = {
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
        poc_filters: pocFilters,
        product_filters: productFilters,
        strategy_id: strategyId,
        client,
    };

    return fetch(url, {
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${token}`,
        },
        method: 'POST',
        body: JSON.stringify(body),
    }).then(response => response.json());
};

export const getStrategyTasksBreakdown = async (
    token: string | undefined,
    startDate: moment.Moment,
    endDate: moment.Moment,
    strategyId: string,
    pocFilters: Record<string, string[]>,
    env: Environment,
): Promise<TasksBreakdown[]> => {
    const url = getNeptuneUrl(env, `strategy/tasks-breakdown-v2`);
    const body = {
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
        strategy_id: strategyId,
        poc_filters: pocFilters,
    };
    return fetch(url, {
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${token}`,
        },
        method: 'POST',
        body: JSON.stringify(body),
    })
        .then(response => response.text())
        .then(text => getSafeAPIResult<TasksBreakdown>(text));
};

export const getStrategyById = async (
    token: string | undefined,
    strategyId: string,
    client: ClientOptions,
    env: Environment,
): Promise<GetStrategy> => {
    const url = getNeptuneUrl(env, `strategy/get/${strategyId}?client=${client}`);
    return fetch(url, {
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${token}`,
        },
    }).then(response => response.json());
};

export const createStrategy = async (
    token: string | undefined,
    strategy: Strategy,
    client: ClientOptions,
    env: Environment,
) => {
    const url = getNeptuneUrl(env, `strategy/create?client=${client}`);
    const body = {
        ...strategy,
        focus_skus: {},
        group_skus: {},
        start_date: moment(strategy?.start_date).format('YYYY-MM-DD'),
        end_date: moment(strategy?.end_date).format('YYYY-MM-DD'),
        client,
    };
    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
    }).then(response => response.json());
};

export const deleteStrategy = async (
    token: string | undefined,
    strategyId: number,
    env: Environment,
): Promise<DeleteStrategyResponse> => {
    const url = getNeptuneUrl(env, `strategy/delete/${strategyId}`);
    return fetch(url, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${token}`,
        },
    }).then(response => response.json());
};

export const updateStrategy = async (
    token: string | undefined,
    strategyId: string,
    strategy: Strategy,
    env: Environment,
): Promise<UpdateStrategyResponse> => {
    const url = new URL(getNeptuneUrl(env, `strategy/update/${strategyId}`));
    const response = await fetch(url, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(strategy),
    });

    if (!response.ok) {
        throw new Error('Failed to update strategy');
    }

    return response.json();
};

export const scheduleStrategy = async (
    token: string | undefined,
    strategyId: string,
    env: Environment,
): Promise<ScheduleStrategyResponse> => {
    const url = new URL(getNeptuneUrl(env, `strategy/schedule-strategy/${strategyId}`));
    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${token}`,
        },
    }).then(response => response.json());
};

export const unscheduleStrategy = async (
    token: string | undefined,
    strategyId: string,
    env: Environment,
): Promise<UnScheduleStrategyResponse> => {
    const url = new URL(getNeptuneUrl(env, `strategy/unschedule-strategy/${strategyId}`));
    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${token}`,
        },
    }).then(response => response.json());
};

export const downloadTasksExcel = async (
    token: string | undefined,
    strategyId: number,
    env: Environment,
) => {
    const url = new URL(getNeptuneUrl(env, `controls/download-tasks-excel/${strategyId}`));
    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${token}`,
        },
    });
};

export const getAutoOptimizedPriorities = async (
    token: string | undefined,
    strategyId: string,
    env: Environment,
): Promise<AutoOptimizePriorities> => {
    const url = getNeptuneUrl(env, `controls/get-auto-optimize-priorities/${strategyId}`);
    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${token}`,
        },
    }).then(response => response.json());
};

export const listSkuGroups = async (
    token: string | undefined,
    active: boolean,
    env: Environment,
    client: ClientOptions,
): Promise<ListGroupSkus> => {
    const url = new URL(getNeptuneUrl(env, `controls/list-group-skus/${client}`));
    if (active) {
        url.searchParams.append('active', 'true');
    } else {
        url.searchParams.append('active', 'false');
    }
    return fetch(url, {
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${token}`,
        },
    }).then(response => response.json());
};

export const createNewSkuGroup = async (
    token: string | undefined,
    skuGroup: SkuGroup,
    env: Environment,
): Promise<CreateGroupSkuResponse> => {
    const url = getNeptuneUrl(env, `controls/create-group-sku`);
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(skuGroup),
        });

        if (!response.ok) {
            const errorBody = await response.text();
            throw new Error(errorBody);
        }
        return await response.json();
    } catch (error) {
        // Check if the error is an instance of TypeError and has a message about failing to fetch
        if (error instanceof TypeError && error.message === 'Failed to fetch') {
            throw new Error('There was an error saving, please try again.');
        } else {
            throw error; // Re-throw other errors unchanged
        }
    }
};

export const addGroupSkuToStrategy = async (
    token: string | undefined,
    strategyId: number,
    groupSkuId: string,
    priority: number,
    env: Environment,
): Promise<AddGroupSkuToStrategy> => {
    const url = getNeptuneUrl(env, `controls/add-group-sku-to-strategy`);
    const body = {
        strategy_id: strategyId,
        group_sku_id: groupSkuId,
        priority,
    };
    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
    }).then(response => response.json());
};

export const addFocusSkuToStrategy = async (
    token: string | undefined,
    strategyId: number,
    pid: string,
    priority: number,
    env: Environment,
): Promise<AddFocusSkuToStrategy> => {
    const url = getNeptuneUrl(env, `controls/add-focus-sku-to-strategy`);
    const body = {
        strategy_id: strategyId,
        focus_sku_id: pid,
        priority,
    };
    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
    }).then(response => response.json());
};

// TODO: is this redundant?
export const getStrategyDataAlt = async (
    token: string | undefined,
    strategyId: string,
    env: Environment,
): Promise<StrategyDataAlt> => {
    const url = getNeptuneUrl(env, `strategy/get/${strategyId}`);
    return fetch(url, {
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${token}`,
        },
    }).then(response => response.json());
};

export const getAvailableExclusions = async (
    token: string | undefined,
    strategyId: string,
    env: Environment,
): Promise<AvailableExclusionChannels> => {
    const url = getNeptuneUrl(env, `controls/get-available-exclusion-channels/${strategyId}`);
    return fetch(url, {
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${token}`,
        },
    }).then(response => response.json());
};

export const updateExclusions = async (
    token: string | undefined,
    strategyId: string,
    exclusions: ExclusionUpdates[],
    env: Environment,
): Promise<UpdateExclusionsResponse> => {
    const body = {
        strategy_id: parseInt(strategyId, 10),
        exclusions,
    };
    const url = getNeptuneUrl(env, `controls/update-exclusions`);
    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
    }).then(response => response.json());
};

export const updateSkuPriorities = async (
    token: string | undefined,
    strategyId: string,
    skuPriorityChanges: SkuPriority[],
    strategyTaskPercentage: number,
    autoOptimize: boolean,
    env: Environment,
): Promise<UpdateSkuPrioritiesResponse> => {
    const url = getNeptuneUrl(env, `controls/update-sku-priorities`);

    const body = {
        strategy_id: parseInt(strategyId, 10),
        sku_priority_changes: skuPriorityChanges,
        strategy_task_percentage: strategyTaskPercentage,
        auto_optimize: autoOptimize,
    };
    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
    }).then(response => response.json());
};

export const archiveGroupSku = async (
    token: string | undefined,
    groupSkuId: number,
    env: Environment,
): Promise<ArchiveGroupSkuResponse> => {
    const url = new URL(getNeptuneUrl(env, `controls/archive-group-sku/${groupSkuId}`));

    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${token}`,
        },
    }).then(response => response.json());
};

export const unArchiveGroupSku = async (
    token: string | undefined,
    groupSkuId: number,
    env: Environment,
): Promise<UnArchiveGroupSkuResponse> => {
    const url = new URL(getNeptuneUrl(env, `controls/unarchive-group-sku/${groupSkuId}`));

    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${token}`,
        },
    }).then(response => response.json());
};

export const getRecommendations = async (
    token: string | undefined,
    strategyId: string,
    use_case: 'QUICK_ORDER' | 'FORGOTTEN_ITEMS' | 'TOP_DEALS' | 'CROSS_SELL_UP_SELL',
    accountid: string,
    env: Environment,
): Promise<LatestExportedRecs> => {
    const url = getNeptuneUrl(env, 'spaceport/get-latest-exported-recs');

    const body = {
        strategy_id: parseInt(strategyId, 10),
        use_case,
        country: 'EC', // hard coding for now
        accountid,
    };

    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
    }).then(response => response.json());
};

export const editGroupSku = async (
    token: string | undefined,
    params: {
        group_sku_id: number;
        task_name: string;
    },
    env: Environment,
): Promise<UpdateGroupSkuResponse> => {
    const url = getNeptuneUrl(env, `controls/update-group-sku`);

    const body = {
        ...params,
    };

    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
    }).then(response => {
        if (!response.ok) {
            throw new Error(`HTTP error, status = ${response.status}`);
        }
        return response.json();
    });
};

export const getControlsTaskSummary = async (
    token: string | undefined,
    strategyId: string | number,
    env: Environment,
): Promise<TaskSummary> => {
    const url = getNeptuneUrl(env, `controls/task-summary/${strategyId}`);
    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${token}`,
        },
    }).then(response => response.json());
};

export const getSkusTasksBreakdown = async (
    token: string | undefined,
    strategyId: string | number,
    sku: string,
    env: Environment,
): Promise<SkuTaskBreakdown> => {
    const url = getNeptuneUrl(env, `controls/sku-tasks-breakdown`);
    const body = {
        strategy_id: strategyId,
        sku,
    };
    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
    }).then(response => response.json());
};

export const getTaskGenerationStatus = async (
    token: string | undefined,
    strategyId: string | number,
    env: Environment,
): Promise<TaskGenerationStatus> => {
    const url = getNeptuneUrl(env, `controls/get-task-generation-status/${strategyId}`);

    return fetch(url, {
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${token}`,
        },
    }).then(async response => {
        if (!response.ok) {
            throw new Error(`HTTP error, status = ${response.status}`);
        }
        const res = await response.json();
        return { ...res, strategyId };
    });
};

export const regenerateTasks = async (
    token: string | undefined,
    strategyId: string | number,
    env: Environment,
    email: string,
    name: string,
): Promise<RegenerateTasksResponse> => {
    const url = getNeptuneUrl(env, `controls/regenerate-tasks/${strategyId}`);
    const body = {
        strategy_id: strategyId,
        email_recipient_name: name,
        email_recipient: email,
    };
    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
    }).then(response => {
        if (!response.ok) {
            throw new Error(`HTTP error, status = ${response.status}`);
        }
        return response.json();
    });
};

export const editGroupSkuAll = async (
    token: string | undefined,
    allChanges: {
        group_sku_id: number;
        group_sku_name: string;
        task_name: string;
    }[],
    env: Environment,
) => Promise.all(allChanges.map(change => editGroupSku(token, change, env)));

export const getFilterValues = async (
    token: string | undefined,
    env: Environment,
    body: {
        client: ClientOptions;
        country: CountryOptions;
        poc_filters: string[];
        product_filters: string[];
    },
): Promise<FilterValuesResponseType> => {
    const url = getNeptuneUrl(env, `strategy/filter-values`);
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(body),
        });

        if (!response.ok) {
            const errorData = await response.json();

            throw new Error(getErrorMessage(response, errorData));
        }

        return await response.json();
    } catch (error) {
        throw new Error(`Error fetching filter values: ${error.message}`);
    }
};

export const getPocExplorer = async (
    token: string | undefined,
    env: Environment,
    body: {
        client: ClientOptions;
        country: CountryOptions;
        vendor_accountid: string;
    },
): Promise<PocExplorerReponseType> => {
    const url = getNeptuneUrl(env, `strategy/poc-explorer`);

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
    });

    if (!response.ok) {
        const errorBody = await response.json();
        throw new Error(errorBody.detail || 'Unknown error');
    }

    const text = await response.text();
    return parseSafeJSON(text) || {};
};

export const getPocExplorerSummary = async (
    token: string | undefined,
    env: Environment,
    client: ClientOptions,
    country: CountryOptions,
): Promise<PocExplorerSummaryReponseType> => {
    const url = getNeptuneUrl(env, `strategy/poc-explorer-summary`);
    const body = {
        client,
        country,
    };

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
    });

    if (!response.ok) {
        const errorBody = await response.json();
        throw new Error(errorBody.detail || 'Unknown error');
    }

    const text = await response.text();
    return parseSafeJSON(text) || {};
};

export const uploadPocMapping = async (token: string, env: Environment, file: File) => {
    const url = getNeptuneUrl(env, `strategy/upload-poc-mapping`);
    const formData = new FormData();
    formData.append('file', file);

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                // 'Content-Type': 'multipart/form-data',
                authorization: `Bearer ${token}`,
            },
            body: formData,
        });

        if (!response.ok) {
            const errorDetails = await response.text();
            throw new Error(errorDetails);
        }

        const data = await response.json();

        return data;
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error uploading file:', error);
        throw error;
    }
};

export const getStrategyChannels = async (
    token: string | undefined,
    env: Environment,
    client: ClientOptions,
    country: CountryOptions,
): Promise<StrategyChannelsResponseType> => {
    const url = getNeptuneUrl(env, `strategy/channels/${client}/${country}`);

    const response = await fetch(url, {
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${token}`,
        },
    });

    if (!response.ok) {
        const errorBody = await response.json();
        throw new Error(errorBody.detail || 'Unknown error');
    }

    const data = await response.json();
    return data;
};
