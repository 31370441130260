import { MantineColorScheme } from '@mantine/core';
import cx from 'classnames';
import React, { CSSProperties, PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import NoDataCard from './noDataCard';
import useColorScheme from '../hooks/useColorScheme';
import { Spinner } from './Spinners';

interface StyledCardProps {
    theme: MantineColorScheme;
}

const StyledCard = styled.div<StyledCardProps>`
    ${({ theme }) => {
        const rgbColors: Record<'light' | 'dark', string> = {
            light: '0, 0, 0',
            dark: '255, 255, 255',
        };

        const rgbColor = rgbColors[theme];

        return `
        border-top: 1px solid rgba(${rgbColor}, 0.1);
        border-left: 1px solid rgba(${rgbColor}, 0.1);
    `;
    }}
    box-shadow: 8px 8px 16px 0px var(--shadow-color);
    background: var(--card-gradient);
    padding: 16px;
`;

export const BareCard = React.forwardRef<
    HTMLDivElement,
    React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
>((props, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { colorScheme } = useColorScheme();

    return <StyledCard ref={ref} theme={colorScheme} {...props} />;
});

export interface CardProps {
    title?: string | JSX.Element;
    actionsElement?: React.ReactNode;
    tooltipElement?: React.ReactNode;
    loading?: boolean;
    error?: string;
    className?: string;
    innerClassName?: string;
    titleClassName?: string;
    errorClassName?: string;
    spinnerColor?: string;
    bareCardStyle?: CSSProperties;
    withDivider?: boolean;
}

export const Card: React.FC<PropsWithChildren<CardProps>> = ({
    children,
    className,
    title,
    actionsElement,
    tooltipElement,
    errorClassName,
    loading,
    error,
    innerClassName,
    titleClassName,
    bareCardStyle,
    withDivider = true,
    spinnerColor,
}) => (
    <BareCard style={bareCardStyle} className={cx('rounded', className)}>
        <div
            className={cx('flex items-center gap-2 justify-between', {
                'border-b border-border-color mb-4 pb-4': withDivider,
            })}
        >
            <div className={cx('flex gap-2', titleClassName)}>
                {typeof title === 'string' ? <h5>{title}</h5> : title}
                {tooltipElement}
            </div>
            {actionsElement}
        </div>
        <div className={innerClassName}>
            {(loading && (
                <div className="flex h-full items-center justify-center py-4">
                    <Spinner aria-label="loading" size={50} color={spinnerColor} />
                </div>
            )) ||
            error ? (
                <NoDataCard className={errorClassName}>{error}</NoDataCard>
            ) : (
                children
            )}
        </div>
    </BareCard>
);
