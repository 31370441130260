import moment from 'moment';
import {
    ChartDataSet,
    DailySummaryCalculatedResponse,
    DailySummayDataPoint,
    DataSegment,
    DiffResult,
    SkuGroup,
    SkuInfo,
    StatsMode,
    StrategiesDataType,
    StrategiesDataTypeWithViewedOrdered,
    TableRow,
} from '../util/types';
import { productColumns } from '../util/util';
import { getStrategyBreakdown } from './api';

export const DEMO_SCALING = 2.5;

function extractNumberFromString(inputStr = '') {
    const regex = /\d+/g;

    return inputStr.match(regex)?.[0];
}

export const mapSkusToList = ({
    skuGroups,
    focusSkus,
    groups = {},
    skuInfoData,
}: {
    skuGroups: SkuGroup[];
    focusSkus: Record<string, number>;
    groups: Record<string, number>;
    skuInfoData: SkuInfo[];
}) => {
    const skuNameMap = skuInfoData.reduce(
        (acc, sku) => {
            acc[sku.pid] = sku.full_name;
            return acc;
        },
        {} as Record<string, string>,
    );

    const mappedGroups: SkuGroup[] = Object.keys(groups || {})
        .map(groupId =>
            skuGroups.find(skuGroup => parseInt(String(skuGroup.id), 10) === parseInt(groupId, 10)),
        )
        .map(
            group =>
                ({
                    ...group,
                    skus_full_name: group?.skus
                        .map(extractNumberFromString)
                        .map(sku => skuNameMap[sku as string]),
                }) as SkuGroup,
        );

    const focusSkusFullName: { sku: string; full_name: string }[] = Object.keys(
        focusSkus || {},
    ).map(sku => ({ sku, full_name: skuNameMap[sku] }));
    const totalFocusProducts: number =
        mappedGroups.reduce((acc, curr) => acc + (curr?.skus?.length || 0), 0) +
        (focusSkusFullName.length || 0);
    return {
        focusSkusFullName,
        mappedGroups,
        totalFocusProducts,
    };
};

export const addAlgoSellingStats = (
    strategy: StrategiesDataType,
): StrategiesDataTypeWithViewedOrdered => {
    const viewed =
        strategy.cross_sell_up_sell_impression_count +
        strategy.deal_sort_impression_count +
        strategy.forgotten_items_impression_count +
        strategy.quick_order_impression_count +
        strategy.top_deals_impression_count;
    const ordered =
        strategy.cross_sell_up_sell_order_count +
        strategy.deal_sort_order_count +
        strategy.forgotten_items_order_count +
        strategy.quick_order_order_count +
        strategy.top_deals_order_count;
    return {
        ...strategy,
        viewed,
        ordered,
    };
};

export const mapToFormat = (item: { sku: string; number_of_tasks: number }) => ({
    sku: parseInt(item.sku, 10),
    count: item.number_of_tasks,
    diff: 0,
});

export function splitFilters(originalObject) {
    const pocFilters = {};
    const productFilters = {};

    Object.keys(originalObject).forEach(key => {
        if (!productColumns.includes(key)) {
            pocFilters[key] = originalObject[key];
        } else {
            productFilters[key] = originalObject[key];
        }
    });

    return { pocFilters, productFilters };
}

export function calculateCumulativeSums(
    data: DailySummayDataPoint[],
    statsMode: StatsMode,
    totalPocs: number,
): DailySummaryCalculatedResponse[] {
    if (!data) return [];
    const cumulativeTotals = {
        revenue: 0,
        coverage: 0,
        mix: 0,
    };

    return data.map(entry => {
        cumulativeTotals.revenue += entry?.revenue || 0;
        cumulativeTotals.coverage += entry?.coverage || 0;
        cumulativeTotals.mix += entry.mix;

        if (statsMode === StatsMode.AllPocs) {
            return {
                date: entry.date,
                revenueDaily: entry?.revenue,
                revenueCumulative: cumulativeTotals?.revenue,
                coverageDaily: entry.coverage,
                coverageCumulative: cumulativeTotals.coverage,
                // always 'per-poc' for mix
                mixDaily: entry.mix / totalPocs,
                mixCumulative: cumulativeTotals.mix / totalPocs,
            };
        }

        return {
            date: entry.date,
            revenueDaily: entry.revenue / totalPocs,
            revenueCumulative: cumulativeTotals.revenue / totalPocs,
            coverageDaily: entry.coverage / totalPocs,
            coverageCumulative: cumulativeTotals.coverage / totalPocs,
            mixDaily: entry.mix / totalPocs,
            mixCumulative: cumulativeTotals.mix / totalPocs,
        };
    });
}

export function getDatesInRange(startDate: string, endDate: string): string[] {
    const start = moment(startDate);
    const end = moment(endDate);
    const dates = [];

    while (start <= end) {
        dates.push(start.format('YYYY-MM-DD'));
        start.add(1, 'days');
    }

    return dates;
}

export function fillMissingDates(
    data: DailySummayDataPoint[],
    startDate: string,
    endDate: string,
): DailySummayDataPoint[] {
    const today = moment().format('YYYY-MM-DD');
    const fullDateList = getDatesInRange(startDate, endDate); // Assuming this returns dates in 'YYYY-MM-DD' format
    const dataMap = new Map(data?.map(item => [item.date, item]));

    let lastDateWithValue = endDate;
    if (moment(endDate).isAfter(today)) {
        // Find the last date with a value before today
        // eslint-disable-next-line no-plusplus
        for (let i = data.length - 1; i >= 0; i--) {
            if (moment(data[i].date).isBefore(today) || data[i].date === today) {
                lastDateWithValue = data[i].date;
                break;
            }
        }
    }

    return fullDateList
        ?.map(date => {
            if (moment(date).isSameOrBefore(lastDateWithValue)) {
                return (
                    dataMap.get(date) || {
                        date,
                        revenue: 0,
                        coverage: 0,
                        mix: 0,
                    }
                );
            }
            return dataMap.get(date);
        })
        .filter(item => item !== undefined);
}

export function calculateDiff(current: DataSegment, previous: DataSegment): DiffResult {
    const metricDiff = current.aggregated_metric - previous.aggregated_metric;
    const percentDiff = metricDiff / previous.aggregated_metric;

    return {
        percentDiff,
        metricDiff,
        value: previous.aggregated_metric,
        totalPocs: previous.pocs_in_group,
        pocsThatPurchased: previous.pocs_that_purchased,
    };
}

export const createQuery = (
    token,
    startDate,
    endDate,
    strategyId,
    filterValues,
    metric,
    groupBy,
    environment,
    summaryOption,
) => ({
    queryKey: [
        'StrategyBreakdown',
        startDate,
        endDate,
        strategyId,
        JSON.stringify(filterValues),
        metric,
        groupBy,
    ],
    queryFn: () =>
        getStrategyBreakdown(
            token,
            startDate,
            endDate,
            strategyId,
            filterValues.pocFilters,
            filterValues.productFilters,
            metric,
            groupBy,
            environment,
        ),
    enabled: Boolean(token && startDate && endDate && strategyId) && summaryOption === 'breakdown',
});

export function buildTableRows(
    currentData: DataSegment[],
    lastMonthData: DataSegment[],
    twoMonthsAgoData: DataSegment[],
    threeMonthsAgoData: DataSegment[],
    statsMode: StatsMode,
): TableRow[] {
    return currentData.map(currentSegment => {
        const defaultSegment = {
            aggregated_metric: 0,
            group_by_name: currentSegment.group_by_name,
            pocs_in_group: 0,
            pocs_that_purchased: 0,
            average_order_value: 0,
        };

        // Find matching segments in previous data sets or use default
        const lastMonthSegment =
            lastMonthData.find(seg => seg.group_by_name === currentSegment.group_by_name) ||
            defaultSegment;
        const twoMonthsAgoSegment =
            twoMonthsAgoData.find(seg => seg.group_by_name === currentSegment.group_by_name) ||
            defaultSegment;
        const threeMonthsAgoSegment =
            threeMonthsAgoData.find(seg => seg.group_by_name === currentSegment.group_by_name) ||
            defaultSegment;

        // Calculate differences
        const comparisons: Array<DiffResult & { key: ChartDataSet }> = [
            {
                ...calculateDiff(currentSegment, lastMonthSegment),
                key: ChartDataSet.LastMonthCurrentStrategy,
            },
            {
                ...calculateDiff(currentSegment, twoMonthsAgoSegment),
                key: ChartDataSet.TwoMonthsAgoCurrentStrategy,
            },
            {
                ...calculateDiff(currentSegment, threeMonthsAgoSegment),
                key: ChartDataSet.ThreeMonthsAgoCurrentStrategy,
            },
        ];

        return {
            segmentName: currentSegment.group_by_name,
            totalPocs: currentSegment.pocs_in_group,
            pocsThatPurchased: currentSegment.pocs_that_purchased,
            metricValue:
                statsMode === StatsMode.AllPocs
                    ? currentSegment.aggregated_metric
                    : currentSegment.aggregated_metric / currentSegment.pocs_that_purchased,
            averageOrderValue: currentSegment.average_order_value,
            comparisons,
            id: currentSegment.group_by_name,
        };
    });
}
