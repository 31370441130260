import Link from 'next/link';
import { useRouter } from 'next/router';
import Image from 'next/image';
import { useMemo } from 'react';
import cx from 'classnames';
import { NavBreadCrumbs } from './navBreadCrumbs';
import { HelmRoutes } from '../../util/routes';
import useStore from '../state/state';

interface LogoBreadcrumbsContainerProps {
    extraContent?: React.ReactNode;
    className?: string;
}
export const LogoBreadcrumbsContainer = ({
    extraContent,
    className,
}: LogoBreadcrumbsContainerProps) => {
    const router = useRouter();
    const demoMode = useStore(state => state.demoMode);
    const image = useMemo(() => {
        if (demoMode) {
            return (
                <Image
                    className="mt-4"
                    alt="bees-logo"
                    src="/images/arenaLogo.svg"
                    width={40}
                    height={40}
                />
            );
        }
        return <Image alt="bees-logo" src="/images/BEES.ai_wordmark.png" width={80} height={40} />;
    }, [demoMode]);
    return (
        <div className={cx('px-6 pb-4 pt-6 flex space-x-4 items-center h-[84px]', className)}>
            <Link id="navbar-home" href={HelmRoutes.HOME(router.query)} className="mb-2 mt-1">
                {image}
            </Link>
            <div>
                <NavBreadCrumbs />
                {extraContent}
            </div>
        </div>
    );
};
