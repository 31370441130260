import { Breadcrumbs } from '@mantine/core';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import cx from 'classnames';
import { HelmRoutes } from '../../util/routes';
import { useClientTranslation } from '../../../../hooks/helm/useClientTranslation';

export const NavBreadCrumbs = () => {
    const router = useRouter();
    const { id } = router.query;
    const { t } = useClientTranslation();
    const items = useMemo(() => {
        const baseItems = [{ title: t('overview'), href: HelmRoutes.HOME(router.query) }];
        if (router.pathname.includes('/create-strategy')) {
            if (!router.query.strategyIdToDuplicate) {
                baseItems.push({
                    title: t('create new strategy'),
                    href: HelmRoutes.CREATE_STRATEGY(router.query),
                });
            } else {
                baseItems.push({
                    title: t('create new strategy'),
                    href: router.asPath,
                });
            }
        }
        if (router.pathname.includes('/edit-strategy')) {
            baseItems.push({
                title: t('edit strategy'),
                href: HelmRoutes.EDIT_STRATEGY(parseInt(id as string, 10), router.query),
            });
        }
        if (router.pathname.includes('/preview-strategy')) {
            baseItems.push({
                title: t('preview strategy'),
                href: HelmRoutes.PREVIEW_STRATEGY(parseInt(id as string, 10), router.query),
            });
        }
        if (router.pathname.includes('/track-strategy')) {
            baseItems.push({
                title: t('track strategy'),
                href: HelmRoutes.TRACK_STRATEGY(parseInt(id as string, 10), router.query),
            });
        }
        if (router.pathname.includes('/tasks-and-groups-editor')) {
            baseItems.push({
                title: t('tasks-and-groups-editor'),
                href: HelmRoutes.TASKS_AND_GROUPS_EDITOR(router.query),
            });
        }
        if (router.pathname.includes('/poc-explorer')) {
            baseItems.push({
                title: t('POC Explorer'),
                href: HelmRoutes.POC_EXPLORER(router.query),
            });
        }
        if (router.pathname.includes('/poc-uploader')) {
            baseItems.push({
                title: t('POC Uploader'),
                href: HelmRoutes.POC_UPLOADER(router.query),
            });
        }
        return baseItems;
    }, [id, router.asPath, router.pathname, router.query, t]);
    const textColorCondition = (index: number) => index === items.length - 1 && items.length !== 1;
    return (
        <Breadcrumbs>
            {items.map((item, index) => (
                <Link
                    href={item.href}
                    key={item.title}
                    className={cx('text-xs', {
                        '!text-navy-70': textColorCondition(index),
                        '!text-gray': !textColorCondition(index),
                    })}
                >
                    {item.title}
                </Link>
            ))}
        </Breadcrumbs>
    );
};
