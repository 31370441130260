import { UseMutateAsyncFunction } from '@tanstack/react-query';
import { NextRouter } from 'next/router';
import { Copy, Trash } from 'phosphor-react';
import React from 'react';
import { showNotification } from '@mantine/notifications';
import { ExclamationMark } from 'tabler-icons-react';
import { StrategyOverviewPartial } from '../../../../hooks/helm/strategyOverviewPartial';
import { RowHoverOption } from '../../components/stickyTable/types';
import { helmColors } from '../../util/colors';
import {
    PocLocation,
    SortDirection,
    StrategiesDataTypeWithViewedOrdered,
    TaskRunningStatus,
} from '../../util/types';
import { HelmRoutes } from '../../util/routes';
import { orientSplitToObjects } from '../../../../hooks/helm/util';
import { StrategiesTableSortByType } from './types';
import { Spinner } from '../../../../andromeda/Spinners/spinner';

export const sortStrategiesTableByColumn = (
    strategies: StrategiesDataTypeWithViewedOrdered[],
    sortOrder: SortDirection,
    sortBy: StrategiesTableSortByType,
) =>
    [...strategies].sort((a, b) => {
        if (sortOrder === 'asc') {
            return a[sortBy] > b[sortBy] ? 1 : -1;
        }
        return a[sortBy] < b[sortBy] ? 1 : -1;
    });

export const filterValidPocsByStrategy = (allPocs: StrategyOverviewPartial[], strategyId: number) =>
    allPocs.filter(
        ({ latitude, longitude, strategy_id }) =>
            parseInt(strategy_id, 10) === strategyId &&
            !(latitude === null || longitude === null || (latitude === 0 && longitude === 0)),
    );

export const determineProgressDisplayColor = (
    percentage: number,
    taskGenerationStatus: TaskRunningStatus,
    hasStrategyScheduledStatus: boolean,
    strategyStartsInFuture: boolean,
) => {
    if (
        taskGenerationStatus === 'running' ||
        (!hasStrategyScheduledStatus && strategyStartsInFuture)
    ) {
        return helmColors.darkGreyBlue;
    }
    if (hasStrategyScheduledStatus && strategyStartsInFuture) {
        return helmColors.tealGreen;
    }
    return percentage === 1 ? helmColors.darkBlue : helmColors.pink;
};

export const formatStrategyOverviewPocLocations = (pocLocations: PocLocation) => {
    const result = pocLocations?.strategy_id.map((item, index) => [
        pocLocations.latitude[index],
        pocLocations.longitude[index],
        pocLocations.strategy_id[index],
    ]);

    return orientSplitToObjects({
        columns: ['latitude', 'longitude', 'strategy_id'],
        data: result,
    });
};

export const getHoverOptionsProps = (
    t: any,
    deletingId: number,
    strategyId: number,
    deleteStrategyIsLoading: boolean,
    deleteStrategyMutateAsync: UseMutateAsyncFunction<
        any,
        Error,
        {
            id: number;
        },
        void
    >,
    router: NextRouter,
    strategyStartsInFuture: boolean,
    unschedule: UseMutateAsyncFunction<
        any,
        Error,
        {
            strategyId: string;
        },
        unknown
    >,
    hasStrategyScheduledStatus: boolean,
) => {
    const onDeleteStrategy = async () => {
        if (hasStrategyScheduledStatus) {
            unschedule({ strategyId: String(strategyId) })
                .then(() => deleteStrategyMutateAsync({ id: strategyId }))
                .catch(() =>
                    showNotification({
                        title: t('Error'),
                        message: t('something went wrong'),
                        color: 'red',
                        icon: <ExclamationMark />,
                    }),
                );
        } else {
            await deleteStrategyMutateAsync({ id: strategyId });
        }
    };
    const handleDuplicateStrategy = () =>
        router.push(
            HelmRoutes.CREATE_STRATEGY_WITH_STRATEGY_ID_TO_DUPLICATE({
                ...router.query,
                strategyIdToDuplicate: strategyId.toString(),
            }),
        );

    const deleteStrategyBtnProps: RowHoverOption = {
        buttonText:
            deletingId === strategyId && deleteStrategyIsLoading ? (
                <Spinner className="ml-2" size={16} />
            ) : (
                t('delete strategy')
            ),
        icon: <Trash className="text-primaryBlue" size={16} />,
        onClick: onDeleteStrategy,
    };

    const duplicateStrategyBtnProps: RowHoverOption = {
        buttonText: t('duplicate strategy'),
        icon: <Copy className="text-primaryBlue" size={16} />,
        onClick: handleDuplicateStrategy,
    };

    if (strategyStartsInFuture) {
        return {
            rowHoverOptions: [deleteStrategyBtnProps, duplicateStrategyBtnProps],
            hoverOptionsWidth: 234,
        };
    }

    return {
        rowHoverOptions: [duplicateStrategyBtnProps],
        hoverOptionsWidth: 234,
    };
};

export const getStrategyTableRowOnClick = (
    strategyStartsInFuture: boolean,
    taskGenerationStatus: TaskRunningStatus,
    strategyId: number,
    router: NextRouter,
) => {
    if (strategyStartsInFuture) {
        if (taskGenerationStatus === 'running') {
            return undefined;
        }
        return () => {
            router.push(HelmRoutes.PREVIEW_STRATEGY(strategyId, router.query));
        };
    }
    return () => router.push(HelmRoutes.TRACK_STRATEGY(strategyId, router.query));
};
