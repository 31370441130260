import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import useStore from '../../components/helm/components/state/state';
import { SkuInfo } from '../../components/helm/util/types';
import { getProductInfo } from '../../components/helm/api/api';
import { useAppContext } from '../../contexts/appContext';
import { mapProductToMock } from '../../components/helm/util/util';

export const useSkuInfo = () => {
    const { user, environment } = useAppContext();
    const client = useStore(state => state.client);
    const country = useStore(state => state.country);
    const demoMode = useStore(state => state.demoMode);
    const forceShowRealSkuNames = useStore(state => state.forceShowRealSkuNames);

    const { data, isLoading, error } = useQuery({
        queryKey: ['ProductInfo', client, environment],
        queryFn: () => getProductInfo(user?.token, client, country, environment),
        enabled: true,
    });

    const filteredResults = useMemo(
        () =>
            data?.products
                ?.filter(item => !!item.full_name)
                .map(item => (demoMode && !forceShowRealSkuNames ? mapProductToMock(item) : item)),
        [data?.products, demoMode, forceShowRealSkuNames],
    ) as SkuInfo[];

    return {
        skuInfoData: filteredResults || [],
        skuInfoLoading: isLoading,
        skuInfoError: error,
    };
};
