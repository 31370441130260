import numeral from 'numeral';

export type NumberFormatType =
    | '0o'
    | '0a'
    | '0.0a'
    | '0.00a'
    | '0[.]00a'
    | '($ 0 a)'
    | '($ 0.0 a)'
    | '($ 0.00 a)'
    | '$0[.]00a'
    | '$0[.]0[0]a'
    | '$0[.]0[000000]a'
    | '0,0'
    | '0,0.0'
    | '0,0.00'
    | '0,0[.]0'
    | '0,0[.]00'
    | '0,0[.]0[0]'
    | '$0,0'
    | '$0.0a'
    | '$0,0.0'
    | '$0,0.00'
    | '$0,0[.]00'
    | '$0,0[.]0[0]'
    | 'R$0,0'
    | 'R$0.00a'
    | 'R$0,0.0'
    | 'R$0,0.00'
    | 'R$0,0[.]00'
    | 'R$0,0[.]0[0]'
    | '0%'
    | '0.0%'
    | '0[.]0%'
    | '0[.]0[00]%'
    | '0.00%'
    | '0[.]00%'
    | '0[.]00[00]%'
    | '0,0[.]00%'
    | '0[.]0[0]%'
    | '(0.00 %)'
    | '($0a)'
    | '$0a'
    | '0[.]0a'
    | '$0[.]0a'
    | '$0.00a'
    | '$0[.]00'
    | '0,0a';

/**
 * formatNumber formats numbers using one of the types
 * @example
 * formatNumber(1000,⬇)
 *   0a =>  1k
 *   0.00a =>  1.00k
 *   0,0 =>  1,000
 *   $0,0.00 =>  $1,000.00
 *   ($ 0.00 a) =>  $ 1.00 k
 *   $0[.]00a =>  $1k
 * @example
 *  formatNumber(1,⬇)
 *   0o => 1st
 * @example
 *  formatNumber(0.1,⬇)
 *   0% => 10%
 *   0.00% => 10.00%
 *   (0.00 %) => 10.00 %
 */
const formatNumber = (number: number | string, type: NumberFormatType): string =>
    type.includes('R')
        ? `${numeral(number).format(type.replace('R', '')).replace('$', 'R$')}`
        : numeral(number).format(type);

export { formatNumber };
