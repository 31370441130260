import classNames from 'classnames';
import React from 'react';

function NoDataCard({
    children = 'WE ARE IN THE PROCESS OF COLLECTING MORE DATA FOR THIS SECTION',
    className,
}: {
    children?: string | JSX.Element;
    className?: string;
}) {
    return (
        <p
            className={classNames(
                'text-center py-4 px-16 bg-secondary rounded-xl text-primary text-xs',
                className,
            )}
            style={{ lineHeight: '1.1rem' }}
        >
            {children}
        </p>
    );
}

export default NoDataCard;
